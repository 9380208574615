import React from 'react'
import SEO from './../components/seo'

import ProductHeader from '../templates/ProductPage/ProductHeader'

const Cgv = () => {
  return (
    <>
      <SEO title="Cgv" description="Page CGV" />
      <ProductHeader title='Cgv' />

      <div className='page-legale'>

        <p><i>applicables à compter du <span>22/09/2021</span></i></p>
        <h3>ARTICLE 1. PARTIES</h3>
        <p>Les présentes conditions générales sont applicables entre <span>JH &amp; Co</span>, <span>SARL
                                                                                               </span>, capital social : <span>1000</span> €, enregistrée au&nbsp;RCS de <span>Creteil</span> en         <span>France</span> le <span>28/11/2016
                                                                                                                                         </span>, sous le numéro <span>82391901400014
                                                                                                                                                                                                                                                        </span>, siège social : <span>1 bis rue blanqui, 94200 Ivry sur seine, FR</span>,
          téléphone : <span>+33620142775</span>, email : <span>jhandco.dev@gmail.com
          </span>, TVA non applicable, article
          293B du CGI, ci-après «&nbsp;l’Editeur&nbsp;» et toute personne, physique ou morale, de droit privé ou de droit
          public, inscrite sur le Site pour acheter un Produit, ci-après «&nbsp;le Client&nbsp;».
        </p>
        <p>&nbsp;</p>
        <h3>ARTICLE 2. DEFINITIONS</h3>
        <p>« <strong>Client&nbsp;</strong>» : toute personne, physique ou morale, de droit privé ou de droit public,
          inscrite sur le Site.
        </p>
        <p>«&nbsp;<strong>Contenus du Site&nbsp;»</strong> : éléments de toute nature publiés sur le Site, protégés ou non
          par un droit de propriété intellectuelle, tel que textes, images, designs, présentations, vidéos, schémas,
          structures, bases de données ou logiciels.&nbsp;
        </p>
        <p>«&nbsp;<strong>L’Editeur</strong>&nbsp;» : <span
          data-mention='{{company:companyName}}'
          className='mention'
                                                      >JH &amp; Co
                                                      </span>, <span
          data-mention='{{company:companyType}}' className='mention'
                 >SARL
                 </span> pris en sa qualité d’éditeur du Site.
        </p>
        <p>«&nbsp;<strong>Internaute</strong>&nbsp;» : toute personne, physique ou morale, de droit privé ou de droit
          public, se connectant au Site.
        </p>
        <p>«&nbsp;<strong>Produit</strong>&nbsp;»&nbsp;: bien de toute nature vendu sur le Site par l’Editeur aux Clients.
        </p>
        <p>«&nbsp;<strong>Site&nbsp;</strong>» : site internet accessible à l’URL <span
          data-mention='{{company:websiteName}}' className='mention'
                                                                                  >https://jhnco.fr
                                                                                  </span>, ainsi que les sous-sites,
          sites miroirs, portails et variations d’URL y afférents.
        </p>
        <h3>ARTICLE 3. CHAMP D’APPLICATION</h3>
        <p>Le Site est d'accès libre et gratuit à tout Internaute. La navigation sur le Site suppose l'acceptation par
          tout Internaute des présentes conditions générales. La simple connexion au Site, par quelque moyen que ce soit,
          notamment par l'intermédiaire d'un robot ou d'un navigateur, emportera acceptation pleine et entière des
          présentes conditions générales. Lors de l’inscription sur le Site, cette acceptation sera confirmée par le fait
          de cocher la case correspondante.
        </p>
        <p>L'Internaute reconnaît du même fait en avoir pris pleinement connaissance et les accepter sans restriction.</p>
        <p>Le fait de cocher la case susvisée sera réputé avoir la même valeur qu'une signature manuscrite de la part de
          l’Internaute. L'Internaute reconnaît la valeur de preuve des systèmes d'enregistrement automatique de L’Editeur
          et, sauf pour lui d'apporter preuve contraire, il renonce à les contester en cas de litige.
        </p>
        <p>Les présentes conditions générales sont applicables aux relations entre les parties à l’exclusion de toutes
          autres conditions, et notamment celles de l’Internaute.&nbsp;
        </p>
        <p>L'acceptation des présentes conditions générales suppose de la part des Internautes qu'ils jouissent de la
          capacité juridique nécessaire pour cela, ou à défaut qu'ils en aient l'autorisation d'un tuteur ou d'un curateur
          s'ils sont incapables, de leur représentant légal s'ils sont mineurs, ou encore qu'ils soient titulaires d'un
          mandat s'ils agissent pour le compte d'une personne morale.
        </p>
        <h3>ARTICLE 4. OBJET DU SITE</h3>
        <p>Le Site a pour objet la vente de Produits aux Clients.</p>
        <h3>ARTICLE 5. ETAPES DE LA COMMANDE</h3>
        <h4><strong>5.1. </strong>Commande</h4>
        <p>Afin de passer commande, les Internautes pourront sélectionner un ou plusieurs Produits et les ajouter à leur
          panier. La disponibilité des Produits est indiquée sur le Site, dans la fiche descriptive de chaque article.
          Lorsque leur commande sera complète, ils pourront accéder à leur panier en cliquant sur le bouton prévu à cet
          effet.
        </p>
        <h4>5.2. Validation de la commande par l’Internaute</h4>
        <p>En consultant leur panier, les Internautes auront la faculté de vérifier le nombre ainsi que la nature des
          Produits qu'ils auront choisis et pourront vérifier leur prix unitaire, ainsi que leur prix global. Ils auront
          la possibilité de retirer un ou plusieurs Produits de leur panier.&nbsp;
        </p>
        <p>Si leur commande leur convient, les Internautes pourront la valider. Ils accéderont alors à un formulaire sur
          lequel ils pourront soit saisir leurs identifiants de connexion s'ils en possèdent déjà, soit s'inscrire sur le
          Site en complétant le formulaire d’inscription au moyen des informations personnelles les concernant.
        </p>
        <h4>5.3. Paiement par le Client</h4>
        <p>Dès lors qu'ils seront connectés ou après qu'ils auront parfaitement complété le formulaire d’inscription, les
          Clients seront invités à contrôler ou modifier leurs coordonnées de livraison et de facturation, puis seront
          invités à effectuer leur paiement en étant redirigés à cet effet sur l'interface de paiement sécurisée
          comportant la mention «&nbsp;commande avec obligation de paiement&nbsp;» ou toute formule analogue.&nbsp;
        </p>
        <h4>5.4. Confirmation de la commande par L’Editeur</h4>
        <p>Une fois le paiement effectivement reçu par L’Editeur, ce dernier s'engage à en accuser réception au Client par
          voie électronique, dans un délai maximal de 24 heures. Dans le même délai, L’Editeur s'engage à adresser au
          Client un courrier électronique récapitulatif de la commande et lui en confirmant le traitement, reprenant
          toutes les informations y afférant.
        </p>
        <h3>ARTICLE 6. PRIX - PAIEMENT</h3>
        <h4>6.1. Prix</h4>
        <p>Les prix applicables sont ceux affichés sur le Site au jour de la commande. Ces prix peuvent être modifiés à
          tout moment par L’Editeur. Les prix affichés ne sont valables qu'au jour de la commande et ne portent pas effet
          pour l'avenir.
        </p>
        <p>Les prix indiqués sur le Site sont entendus en euros, toutes taxes comprises, hors frais de livraison.</p>
        <h4>6.2. Modalité de paiement</h4>
        <p>Le Client peut effectuer son règlement par <span
          data-mention='{{company:paymentGateways}}'
          className='mention'
                                                      >Stripe
                                                      </span>.
        </p>
        <p>Dans le cadre des paiements par carte bancaire, L’Editeur n'a accès à aucune donnée relative aux moyens de
          paiement du Client. Le paiement est effectué directement entre les mains de l'établissement bancaire.
        </p>
        <p>En cas de paiement par mandat, chèque ou virement bancaire, les délais de livraison ne commencent à courir qu'à
          compter de la date de l’encaissement du paiement par L’Editeur.
        </p>
        <h4>6.3. Facturation</h4>
        <p>L’Editeur adressera ou mettra à disposition du Client une facture par voie électronique après chaque paiement.
          Le Client accepte expressément de recevoir les factures par voie électronique.
        </p>
        <h4>6.4. Défaut de paiement</h4>
        <p>Les dates de paiement convenues ne peuvent être retardées sous quelque prétexte que ce soit, y compris en cas
          de litige.
        </p>
        <p>Toute somme non payée à l’échéance donnera lieu, de plein droit et sans mise en demeure, à l’application de
          pénalités de retard calculées sur la base d’un taux égal à 3 fois le taux d’intérêt légal, sans que cette
          pénalité nuise à l’exigibilité des sommes dues en principal.
        </p>
        <p>En outre, tout retard de paiement aura pour conséquence la facturation au Client défaillant de frais de
          recouvrement d'un montant de 40 euros, l’exigibilité immédiate de toutes les sommes restant dues quels que
          soient les délais convenus, majorées d’une indemnité de 20 % du montant à titre de clause pénale, ainsi que la
          possibilité de résilier le contrat unilatéralement au tort du Client. La présente clause s’inscrit dans le cadre
          des dispositions de l’article 1152 du code civil permettant au juge de réduire l’indemnité si le juge estime
          qu’elle est excessive.
        </p>
        <h4>6.5. Réserve de propriété</h4>
        <p>Les Produits vendus restent la propriété de L’Editeur jusqu'à complet paiement de leur prix, conformément à la
          présente clause de réserve de propriété.
        </p>
        <h3>ARTICLE 7. SERVICE CLIENTELE</h3>
        <p>&nbsp;</p>
        <p>Le service clientèle du Site est accessible depuis la page contact du site: <span
          data-mention='{{company:afterSaleWebPage}}' className='mention'
                                                                                       >https://jhnco.fr/contact
                                                                                       </span> ou par email
          à <span data-mention='{{company:afterSaleEmail}}' className='mention'>jhandcoparis@gmail.com</span> ou par
          courrier postal à l’adresse indiquée à l’article 1 des présentes conditions générales.
        </p>
        <h3>ARTICLE 8. ESPACE PERSONNEL</h3>
        <h4>8.1. Création de l’espace personnel</h4>
        <p>La création d'un espace personnel est un préalable indispensable à toute commande d'un Internaute sur le Site.
          A cette fin, l’Internaute sera invité à fournir un certain nombre d'informations personnelles. Certaines de ces
          informations sont réputées indispensables à la création de l'espace personnel. Le refus par un Internaute de
          fournir lesdites informations aura pour effet d'empêcher la création de l'espace personnel ainsi que,
          incidemment, la validation de la commande.
        </p>
        <p>Lors de la création de l'espace personnel, l'Internaute est invité à choisir un mot de passe. Ce mot de passe
          constitue la garantie de la confidentialité des informations contenues dans l’espace personnel. L'Internaute
          s'interdit donc de le transmettre ou de le communiquer à un tiers. A défaut, L’Editeur ne pourra être tenu pour
          responsable des accès non autorisés à l’espace personnel d'un Internaute.
        </p>
        <p>Le Client s’engage à procéder à une vérification régulière des données qui le concernent et à procéder en
          ligne, depuis son espace personnel, aux actualisations et modifications nécessaires.
        </p>
        <h4>8.2. Contenu de l’espace personnel</h4>
        <p>L’espace personnel permet au Client de consulter et de suivre toutes ses commandes effectuées sur le Site.</p>
        <p>Les pages relatives aux espaces personnels sont librement imprimables par le titulaire du compte en question,
          mais ne constituent nullement une preuve admissible par un tribunal. Elles n'ont qu'un caractère informatif
          destiné à assurer une gestion efficace de ses commandes par le Client.
        </p>
        <p>L’Editeur s'engage à conserver de façon sécurisée tous les éléments contractuels dont la conservation est
          requise par la loi ou la réglementation en vigueur.
        </p>
        <h4>8.3. Suppression de l’espace personnel</h4>
        <p>L’Editeur se réserve le droit de supprimer le compte de tout Client qui contrevient aux présentes conditions
          générales, notamment lorsque le Client fournit des informations inexactes, incomplètes, mensongères ou
          frauduleuses, ainsi que lorsque l’espace personnel d’un Client sera resté inactif depuis au moins une année.
          Ladite suppression ne sera pas susceptible de constituer une faute de L’Editeur ou un dommage pour le Client
          exclu, qui ne pourra prétendre à aucune indemnité de ce fait.
        </p>
        <p>Cette exclusion est sans préjudice de la possibilité, pour L’Editeur, d'entreprendre des poursuites d'ordre
          judiciaire à l'encontre du Client, lorsque les faits l'auront justifié.
        </p>
        <h3>ARTICLE 9. DONNEES PERSONNELLES</h3>
        <p>Dans le cadre de sa prestation, l’Editeur va être amené à traiter des données à caractère personnel de ses
          Clients.
        </p>
        <h4>9.1. Identité du responsable du traitement</h4>
        <p>Le responsable de la collecte et des données traitées sur le Site est l’Editeur.</p>
        <h4>9.2. Identité du Délégué à la protection des données</h4>
        <p>Le délégué à la protection des données est : Etienne Deshoulières, 121 boulevard de Sébastopol 75002 Paris, <a
          href='mailto:contact@deshoulieres-avocats.com'
                                                                                                                       >contact@deshoulieres-avocats.com
                                                                                                                       </a>, 01 77 62 82 03, <a
          href='http://www.deshoulieres-avocats.com' target='_blank'
                              >www.deshoulieres-avocats.com
                              </a>" en prenant toujours
          soin de créer un lien hypertexte sur l'URL de notre site
        </p>
        <h4>9.3. Données collectées</h4>
        <h5>9.3.1. Données collectées auprès des clients</h5>
        <p>Dans le cadre de ses relations contractuelles, l’Editeur peut être amené à collecter et traiter des
          informations de ses Clients, à savoir&nbsp;: <span data-mention='{{privacy:collectInfo}}' className='mention'>Email, Nom et prénom, Téléphone</span>.
        </p>
        <h5>9.3.2. Données collectées auprès des clients</h5>
        <p>Les données collectées lors de la relation contractuelle font l'objet d'un traitement automatisé ayant pour
          finalité de :
        </p>
        <ul>
          <li>Engager des procédures judiciaires ;</li>
          <li>Vérifier l'identité des Clients ;</li>
        </ul>
        <h5>9.3.3. Bases juridiques du traitement</h5>
        <p>Les données collectées ont pour base juridique une relation contractuelle.</p>
        <h5>9.3.4. Destinataires des données</h5>
        <p>Les données collectées sont consultables uniquement par l’Editeur dans les limites strictement nécessaires à
          l’exécution des engagements contractuels.
        </p>
        <p>Ces données, que ce soit sous forme individuelle ou agrégée, ne sont jamais rendues librement visualisables par
          une personne physique tierce.
        </p>
        <h5>9.3.5. Durée de conservation des données personnelles</h5>
        <p>Les données personnelles collectées sont conservées pendant le temps de la relation contractuelle, et pendant
          le temps durant lequel la responsabilité de l’Editeur peut être engagée.
        </p>
        <p>Passé le délai de conservation, l’Editeur s’engage à supprimer définitivement les données des personnes
          concernées sans en conserver une copie.
        </p>
        <h5>9.3.6. Sécurité et confidentialité des données personnelles</h5>
        <p>Les données personnelles sont conservées dans des conditions sécurisées, selon les moyens actuels de la
          technique, dans le respect des dispositions du Règlement général sur la protection des données et de la
          législation nationale en vigueur.
        </p>
        <p>L’accès aux locaux de l’Editeur est également sécurisé.</p>
        <h5>9.3.7. Minimisation des données</h5>
        <p>L’Editeur peut également collecter et traiter toute donnée transmise volontairement par ses Clients.</p>
        <p>L’Editeur oriente ses Clients afin qu’il fournisse des données à caractère personnel strictement nécessaires à
          l’exécution des engagements contractuels.
        </p>
        <p>L’Editeur s’engage à ne conserver et traiter que les données strictement nécessaires à ses activités
          professionnelles, et supprimera toute donnée reçue non utile à ses activités dans les plus brefs délais.
        </p>
        <h4>9.4. Respect des droits</h4>
        <p>Les Clients de l’Editeur disposent des droits suivants concernant leurs données personnelles, qu’ils peuvent
          exercer en écrivant à l’adresse postale de l’Editeur &nbsp;ou en remplissant le formulaire contact en ligne.
        </p>
        <h5>9.4.1. Droit d’information, d’accès et de communication des données</h5>
        <p>Les Clients de l’Editeur ont la possibilité d’accéder aux données personnelles qui les concernent.</p>
        <p>En raison de l’obligation de sécurité et de confidentialité dans le traitement des données à caractère
          personnel qui incombe à l’Editeur, les demandes seront uniquement traitées si les Clients rapportent la preuve
          de leur identité, notamment par la production d’un scan de leur titre d’identité valide (en cas de demande par
          le formulaire électronique dédié) ou d’une photocopie signée de leur titre d’identité valide (en cas de demande
          adressée par écrit), tous deux accompagnés de la mention «&nbsp;j’atteste sur l’honneur que la copie de cette
          pièce d’identité est conforme à l’original. Fait à … le …&nbsp;», suivie de leur signature.
        </p>
        <p>Pour les aider dans leur démarche, les Clients trouveront <a
          href='https://www.cnil.fr/fr/modele/courrier/exercer-son-droit-dacces' target='_blank'
                                                                     >ici
                                                                     </a>&nbsp; un modèle
          de courrier élaboré par la Cnil.
        </p>
        <h5>9.4.2. Droit de rectification, de suppression et droit à l’oubli des données</h5>
        <p>Les Clients de l’Editeur ont la possibilité de demander la rectification, la mise à jour, le verrouillage ou
          encore l’effacement de leurs données personnelles qui peuvent s’avérer le cas échéant inexactes, erronées,
          incomplètes ou obsolètes.
        </p>
        <p>Les Clients de l’Editeur peuvent également définir des directives générales et particulières relatives au sort
          des données à caractère personnel après leur décès. Le cas échéant, les héritiers d’une personne décédée peuvent
          exiger de prendre en considération le décès de leur proche et/ou de procéder aux mises à jour nécessaires.
        </p>
        <p>Pour les aider dans leur démarche, les Clients trouveront <a
          href='https://www.cnil.fr/fr/modele/courrier/rectifier-des-donnees-inexactes-obsoletes-ou-perimees'
          target='_blank'
                                                                     >ici
                                                                     </a>&nbsp; un modèle de courrier élaboré par la Cnil.
        </p>
        <h5>9.4.3. Droit d’opposition au traitement de données</h5>
        <p>Les Clients de l’Editeur ont la possibilité de s’opposer à un traitement de leurs données personnelles.</p>
        <p>Pour les aider dans leur démarche, les Clients trouveront <a
          href='https://www.cnil.fr/fr/modele/courrier/rectifier-des-donnees-inexactes-obsoletes-ou-perimees'
          target='_blank'
                                                                     >ici
                                                                     </a>&nbsp; un modèle de courrier élaboré par la Cnil.
        </p>
        <h5>9.4.4. Droit à la portabilité des données</h5>
        <p>Les Clients de l’Editeur ont le droit de recevoir les données personnelles qu’ils ont fournies à l’Editeur dans
          un format transférable, ouvert et lisible.
        </p>
        <h5>9.4.5. Droit à la limitation du traitement</h5>
        <p>Les Clients de l’Editeur ont le droit de demander que le traitement de leurs données personnelles par l’Editeur
          soit limité. Ainsi, leurs données ne pourront qu’être conservées et non plus utilisées par l’Editeur.
        </p>
        <h5>9.4.6. Délais de réponse</h5>
        <p>L’Editeur s’engage à répondre à toute demande d’accès, de rectification ou d’opposition ou toute autre demande
          complémentaire d’informations dans un délai raisonnable qui ne saurait dépasser 1 mois à compter de la réception
          de la demande.
        </p>
        <h5>9.4.7. Plainte auprès de l’autorité compétente</h5>
        <p>Si les Clients de l’Editeur considèrent que l’Editeur ne respecte pas ses obligations au regard de leurs
          données à caractère personnel, ils peuvent adresser une plainte ou une demande auprès de l’autorité compétente.
          En France, l’autorité compétente est la Cnil à laquelle ils peuvent adresser une demande <a
            href='https://www.cnil.fr/fr/plaintes/internet' target='_blank'
                                                                                                   >ici
          </a>.
        </p>
        <h4>9.5. Transfert des données collectées</h4>
        <h5>9.5.1. Transfert à des partenaires</h5>
        <p>L’Editeur a recours à des prestataires habilités pour faciliter la collecte et le traitement des données de ses
          Clients. Ces prestataires peuvent être situés en dehors de l’Union Européenne.
        </p>
        <p>L’Editeur s’est préalablement assuré de la mise en œuvre par ses prestataires de garanties adéquates et du
          respect de conditions strictes en matière de confidentialité, d’usage et de protection des données, par exemple
          via le Privacy Shield états-unien.
        </p>
        <p>L’Editeur fait appel aux sous-traitants suivants :</p>
        <p>&nbsp;</p>
        <table>
          <tbody>
            <tr>
              <td>Partenaire</td>
              <td>Qualité</td>
              <td>Pays&nbsp;destinataire</td>
              <td>Traitement réalisé</td>
              <td>Garanties</td>
            </tr>
            <tr>
              <td />
              <td />
              <td />
              <td />
              <td />
            </tr>
          </tbody>
        </table>
      &nbsp;
        <p>&nbsp;</p>
        <h5>9.5.2. Transfert sur réquisition ou décision judiciaire</h5>
        <p>Les Clients consentent également à ce que l’Editeur communique les données collectées à toute personne, sur
          réquisition d’une autorité étatique ou sur décision judiciaire.
        </p>
        <h5>9.5.3. Transfert dans le cadre d'une fusion ou d'une acquisition</h5>
        <p>Si l’Editeur est impliqué dans une fusion, une vente d'actifs, une opération de financement, une liquidation ou
          banqueroute ou dans une acquisition de tout ou partie de son activité par une autre société, les Clients
          consentent à ce que les données collectées soient transmises par l’Editeur à cette société et que cette société
          opère les traitements de données personnelles visés dans les présentes Conditions générales de service au lieu
          et place de l’Editeur.
        </p>
        <h3>ARTICLE 10. RESPONSABILITE DE L’EDITEUR</h3>
        <h4>10.1. Nature des obligations de L’Editeur</h4>
        <p>L’Editeur s'engage à apporter le soin et la diligence nécessaire à la fourniture de Produits de qualité
          conforme aux spécifications des présentes Conditions générales. L’Editeur ne répond que d'une obligation de
          moyens concernant les services objet des présentes.
        </p>
        <h4><strong>10.2. Force majeure - Faute du Client</strong></h4>
        <p>L’Editeur n'engagera pas sa responsabilité en cas de force majeure ou de faute du Client, telles que définies
          au présent article :
        </p>
        <h5>10.2.1. Force majeure</h5>
        <p>Au sens des présentes conditions générales, sera considéré comme un cas de force majeure opposable au Client
          tout empêchement, limitation ou dérangement du Service du fait d'incendie, d'épidémie, d'explosion, de
          tremblement de terre, de fluctuations de la bande passante, de manquement imputable au fournisseur d'accès, de
          défaillance des réseaux de transmission, d'effondrement des installations, d'utilisation illicite ou frauduleuse
          des mots de passe, codes ou références fournis au Client, de piratage informatique, d'une faille de sécurité
          imputable à l'hébergeur du Site ou aux développeurs, d'inondation, de panne d'électricité, de guerre, d'embargo,
          de loi, d'injonction, de demande ou d'exigence de tout gouvernement, de réquisition, de grève, de boycott, ou
          autres circonstances hors du contrôle raisonnable de L’Editeur. Dans de telles circonstances, L’Editeur sera
          dispensé de l'exécution de ses obligations dans la limite de cet empêchement, de cette limitation ou de ce
          dérangement.
        </p>
        <h5>10.2.2. Faute du Client</h5>
        <p>Au sens des présentes Conditions générales, sera considéré comme une faute du Client opposable à ce dernier
          toute mauvaise utilisation du Service, faute, négligence, omission ou défaillance de sa part ou de celle de ses
          préposés, non-respect des conseils donnés par L’Editeur sur son Site, toute divulgation ou utilisation illicite
          du mot de passe, des codes et références du Client, ainsi que le renseignement d’informations erronées ou
          l’absence de mise à jour de telles informations dans son espace personnel. Sera également considérée comme une
          faute du Client la mise en œuvre de tout procédé technique, tels que des robots, ou des requêtes automatiques,
          dont la mise en œuvre contreviendrait à la lettre ou à l’esprit des présentes conditions générales de vente.
        </p>
        <h4>10.3. Problèmes techniques - Liens hypertextes</h4>
        <p>En cas d'impossibilité d'accès au Site, en raison de problèmes techniques de toutes natures, le Client ne
          pourra se prévaloir d'un dommage et ne pourra prétendre à aucune indemnité. L'indisponibilité, même prolongée et
          sans aucune durée limitative, d'un ou plusieurs services en ligne, ne peut être constitutive d'un préjudice pour
          les Clients et ne peut aucunement donner lieu à l'octroi de dommages et intérêts de la part de L’Editeur.
        </p>
        <p>Les liens hypertextes présents sur le Site peuvent renvoyer sur d'autres sites internet. La responsabilité de
          L’Editeur ne saurait être engagée si le contenu de ces sites contrevient aux législations en vigueur. De même la
          responsabilité de L’Editeur ne saurait être engagée si la visite, par l'Internaute, de l'un de ces sites, lui
          causait un préjudice.
        </p>
        <p>En l'état actuel de la technique, le rendu des représentations des Produits proposés à la vente sur le présent
          Site, notamment en termes de couleurs ou de formes, peut sensiblement varier d'un poste informatique à un autre
          ou différer de la réalité selon la qualité des accessoires graphiques et de l'écran ou selon la résolution de
          l'affichage. Ces variations et différences ne pourront en nulle hypothèse être imputées à L’Editeur qui ne
          pourra en aucun cas voir sa responsabilité engagée de ce fait.
        </p>
        <h4>10.4. <strong>Dommages-intérêts à la charge de L’Editeur</strong></h4>
        <p>A défaut de dispositions légales ou réglementaires contraires, la responsabilité de L’Editeur est limitée au
          préjudice direct, personnel et certain subi par le Client et lié à la défaillance en cause. L’Editeur ne pourra
          en aucun cas être tenu responsable des dommages indirects tels que, notamment les pertes de données, les
          préjudices commerciaux, les pertes de commandes, les atteintes à l'image de marque, les troubles commerciaux et
          les pertes de bénéfices ou de clients. De même et dans les mêmes limites, le montant des dommages et intérêts
          mis à la charge de L’Editeur ne pourra en tout état de cause excéder le prix du Produit commandé.
        </p>
        <h4>10.5. <strong>Liens hypertextes et contenus du Site</strong></h4>
        <p>Les Contenus du Site sont publiés à titre indicatif, sans garantie d'exactitude. L’Editeur ne peut en aucun cas
          être tenu responsable du fait d'une omission, d'une inexactitude ou de toute erreur contenue dans ces
          informations et qui serait à l'origine d'un dommage direct ou indirect causé à l'Internaute.
        </p>
        <h3><strong>ARTICLE 11. PROPRIETE INTELLECTUELLE</strong></h3>
        <h4><strong>11.1. Protection légale des Contenus du Site</strong></h4>
        <p>Les Contenus du Site sont susceptibles d'être protégés par le droit d'auteur et le droit des bases de données.
          Toute représentation, reproduction, traduction, adaptation ou transformation, intégrale ou partielle, réalisée
          illégalement et sans le consentement de l’Editeur ou de ses ayants droit ou ayants cause constitue une violation
          des Livres I et III du Code de la propriété intellectuelle et sera susceptible de donner lieu à des poursuites
          judiciaires pour contrefaçon.
        </p>
        <h4><strong>11.2. Protection contractuelle des Contenus du Site</strong></h4>
        <p>L'Internaute s'engage contractuellement à l'égard de l'Editeur à ne pas utiliser, reproduire ou représenter, de
          quelque manière que ce soit, les Contenus du Site, qu'ils soient ou non protégés par un droit de propriété
          intellectuelle, à une autre fin que celle de leur lecture par un robot ou un navigateur. Cette interdiction
          n'est pas applicable aux robots d'indexation ayant pour seul objet de scanner le contenu du Site aux fins
          d'indexation.
        </p>
        <h3><strong>ARTICLE 12. STIPULATIONS FINALES</strong></h3>
        <h4><strong>12.1. Droit applicable</strong></h4>
        <p>Les présentes conditions générales sont soumises à l'application du droit français.</p>
        <h4><strong>12.2. Modifications des présentes conditions générales</strong></h4>
        <p>Les présentes conditions générales peuvent être modifiées à tout moment par L’Editeur. Les conditions générales
          applicables au Client sont celles en vigueur au jour de sa commande ou de sa connexion sur le présent Site,
          toute nouvelle connexion à l'espace personnel emportant acceptation le cas échéant des nouvelles conditions
          générales.&nbsp;
        </p>
        <h4><strong>12.3. Litiges</strong></h4>
        <p>En vertu de l’ordonnance n°2015-1033 du 20 août 2015, tous litiges qui pourraient survenir dans le cadre de
          l'exécution des présentes conditions générales et dont la solution n’aura pu être trouvée préalablement à
          l’amiable entre les parties devra être soumis.
        </p>
        <p>En outre, le Client est informé de l’existence de la plateforme de règlement en ligne des litiges, accessibles
          à l’adresse URL suivante&nbsp;: <a
            href='https://ec.europa.eu/consumers/odr/main/?event=main.home2.show'
            target='_blank'
                                          >https://ec.europa.eu/consumers/odr/main/?event=main.home2.show
          </a>&nbsp;
        </p>
        <p>Depuis le 1er janvier 2016, la médiation est obligatoire pour tous. Ainsi, tout professionnel vendant à des
          particuliers, est tenu de communiquer les coordonnées d'un Médiateur compétent en cas de litige, et ce peu
          importe qu'il vende à distance ou dans un magasin physique (Source: FEVAD).
        </p>
        <p><span data-mention='{{company:mediationServiceName}}' className='mention'>Contact</span> &nbsp;/&nbsp; <span
          data-mention='{{company:mediationUrl}}' className='mention'
                                                                                                                  >https://jhnco.fr/contact
                                                                                                                  </span>
        </p>
        <h4><strong>12.4. Entièreté</strong></h4>
        <p>La nullité d'une des clauses du présent contrat n'entraînera pas la nullité des autres clauses du contrat ou du
          contrat dans sa globalité, qui garderont leur plein effet et portée. Dans une telle hypothèse, les parties
          devront dans la mesure du possible remplacer la stipulation annulée par une stipulation valable correspondant à
          l'esprit et à l'objet des présentes.
        </p>
        <h4><strong>12.5. Non-renonciation</strong></h4>
        <p>L'absence d'exercice par L’Editeur des droits qui lui sont reconnus par les présentes ne pourra en aucun cas
          être interprétée comme une renonciation à faire valoir lesdits droits.
        </p>
        <h4><strong>12.6. Démarchage téléphonique</strong></h4>
        <p>Le Client est informé qu’il a la possibilité de s’inscrire sur la liste d'opposition au démarchage téléphonique
          à l’adresse <a href='http://www.bloctel.gouv.fr/.' target='_blank'>http://www.bloctel.gouv.fr/.</a>
        </p>
        <h4><strong>12.7. Langues des présentes conditions générales</strong></h4>
        <p>Les présentes conditions générales sont proposées en français.</p>
        <h4><strong>12.8. Clauses abusives</strong></h4>
        <p>Les stipulations des présentes conditions générales s'appliquent sous réserve du respect des dispositions
          impératives du Code de la consommation concernant les clauses abusives dans les contrats conclus entre un
          professionnel et un consommateur.
        </p>

      </div>
    </>

  )
}

export default Cgv
